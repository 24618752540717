<template>
  <AppPage :show-menu="false" class="centered" :show-footer="false">
    <FormOnboard
      :initial-form-data="$store.state.user.user || {}"
      :update="user => $store.dispatch('user/update', user)"
    />
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import AppPage from "@/components/organisms/AppPage.vue";
import FormOnboard from "@/components/organisms/FormOnboard.vue";

export default Vue.extend({
  components: {
    AppPage,
    FormOnboard
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';

.centered{
  text-align: center;
}
/deep/ .page-content{
  max-width: 30em;
  padding: 0 1em;
  margin: 0 auto;
}
@media(min-width: @fifthbreakpoint){
  .centered{
    font-size: 120%;
  }
}


</style>
