<template>
  <form>
    <div v-if="sectionToShow === 'loading'">
      <h2>Loading... {{isOnBoardingComplete}}</h2>
    </div>
    <div v-show="sectionToShow === 'one'">
      <h2>Set up your profile</h2>
      <div class="name-inputs" v-if="!initialFormData.name || !initialFormData.lastName">
        <InputText placeholder="First name" :value="formData.name" name="name" />
        <InputText placeholder="Last name" :value="formData.lastName" name="lastName" />
      </div>
      <p>What describes you best?</p>
      <div class="options">
        <div
          v-for="jobTitle in jobTitles"
          :key="jobTitle"
          :class="{selected: formData.jobTitle === jobTitle}"
          @click="setFieldValue('jobTitle', jobTitle)"
        >
          {{jobTitle}}
        </div>
      </div>
      <SmoothReflow>
        <InputText
          v-show="formData.jobTitle == 'Other'"
          placeholder="What's your role?"
          :value="formData.customJobTitle"
          name="customJobTitle"
          :change-on-key-up="true"
          style="margin:0;"
        />
      </SmoothReflow>
      <Button
        class="special uppercase full-width"
        :disabled="!isFirstSectionValid"
        @click="validateAndSubmit"
        ref="buttonFirstSectionSubmit"
      >
        Continue
      </Button>
    </div>
    <div v-show="sectionToShow === 'two'">
      <h2>What's are you most interested in?</h2>
      <div class="options wide">
        <div
          v-for="useCase in useCases"
          :key="useCase"
          :class="{selected: formData.reasonForSigningUp === useCase}"
          @click="setFieldValue('reasonForSigningUp', useCase)"
        >
          {{useCase}}
        </div>
      </div>
      <SmoothReflow>
        <InputText
          v-show="formData.reasonForSigningUp == 'Other'"
          placeholder="What's your goal?"
          :value="formData.customReasonForSigningUp"
          name="customReasonForSigningUp"
          :change-on-key-up="true"
          style="margin:0;"
        />
      </SmoothReflow>
      <Button
        class="special uppercase full-width"
        :disabled="!isSecondSectionValid"
        @click="validateAndSubmit"
        ref="buttonSecondSectionSubmit"
      >
        Continue
      </Button>
    </div>
    <div v-show="sectionToShow === 'three'">
      <h2>Create your workspace</h2>
      <p>Team or Individual?</p>
      <div class="options">
        <div
          v-for="option in individualOrCompanyOptions"
          :key="option"
          :class="{selected: formData.individualOrCompany === option}"
          @click="setFieldValue('individualOrCompany', option)"
        >
          {{option}}
        </div>
      </div>
      <SmoothReflow>
        <InputText
          v-show="formData.individualOrCompany == 'Team'"
          placeholder="Company name"
          :value="formData.companyName"
          name="companyName"
          :change-on-key-up="true"
          style="margin:0;"
        />
      </SmoothReflow>
      <p><small>You can always change this later.</small></p>
      <Button
        class="special uppercase full-width"
        :disabled="!isThirdSectionValid"
        @click="validateAndSubmit"
        ref="buttonThirdSectionSubmit"
      >
        Continue
      </Button>
    </div>

    <div v-show="sectionToShow === 'four'">
      <h2>What do you work on most?</h2>
      <div class="options wide">
        <div
          v-for="customerType in customerTypes"
          :key="customerType"
          :class="{selected: formData.customerType === customerType}"
          @click="setFieldValue('customerType', customerType)"
        >
          {{customerType}}
        </div>
      </div>
      <SmoothReflow>
        <InputText
          v-show="formData.customerType == 'Other'"
          placeholder="What do you work on most?"
          :value="formData.customCustomerType"
          name="customCustomerType"
          :change-on-key-up="true"
          style="margin:0;"
        />
      </SmoothReflow>
      <Button
        class="special uppercase full-width"
        :disabled="!isFourthSectionValid"
        @click="validateAndSubmit"
        ref="buttonFourthSectionSubmit"
      >
        Complete & Download
      </Button>
    </div>
    <div v-show="sectionToShow === 'loading'">
      Loading...
    </div>
  </form>
</template>
<script lang="ts">
import Vue from 'vue';
import MixinForm from "@/components/MixinForm.vue";
import SmoothReflow from "@/components/atoms/SmoothReflow.vue";

export default Vue.extend({
  components: {SmoothReflow},
  mixins: [MixinForm],
  data(){
    return {
      useCases: [
        'Learning skills and techniques',
        'Pitching and presenting',
        'Production planning (logistics, estimates, etc)',
        'Remixing & repurposing content',
        'Proofing and QA',
        'Education',
        'Other'
      ],
      customerTypes: [
        'Commercials & Ads',
        'Wedding Videos',
        'Corporate Videos',
        'Social Content',
        'Film & TV',
        'Documentary',
        'Other'
      ],
      jobTitles: [
        'Director',
        'Cinematographer',
        'Marketer / Advertiser',
        'Producer',
        'Editor',
        'Content Creator',
        'Writer',
        'Actor',
        'Artist / Designer',
        'Composer or Musician',
        'Educator',
        'Student',
        'Sound Designer',
        'Other'
      ],
      individualOrCompanyOptions: [
        'Individual',
        'Team'
      ],
    }
  },
  computed: {
    user(){
      return this.$store.getters['user/me']
    },
    isJobTitleSelected(){
      return this.formData.jobTitle
    },
    isFirstSectionValid(){
      if(!this.formData.jobTitle) return false; // require jobtitle
      if(!this.formData.name) return false; // require name
      return (
        this.formData.customJobTitle ||
        this.formData.jobTitle != 'Other'
      )
    },
    isFirstSectionComplete(){
      return !!(this.user.name && this.user.jobTitle)
    },
    isSecondSectionValid(){
      if(!this.formData.reasonForSigningUp) return false;
      return (
        this.formData.customReasonForSigningUp ||
        this.formData.reasonForSigningUp != 'Other'
      )
    },
    isSecondSectionComplete(){
      return !!this.user.reasonForSigningUp
    },
    isThirdSectionValid(){
      return !!(
        this.formData.individualOrCompany == 'Individual' ||
        this.formData.companyName
      )
    },
    isThirdSectionComplete(){
      return !!(this.user.individualOrCompany === 'Individual' || this.user.companyName)
    },
    isFourthSectionValid(){
      return this.formData.customCustomerType || (
        this.formData.customerType != 'Other' &&
        this.formData.customerType
      )
    },
    isFourthSectionComplete(){
      return !!this.user.customerType
    },
    isOnBoardingComplete(){
      return this.isFirstSectionComplete && this.isSecondSectionComplete && this.isThirdSectionComplete && this.isFourthSectionComplete;
    },
    sectionToShow(){
      if(!this.isFirstSectionComplete){
        return 'one';
      }
      if(!this.isSecondSectionComplete){
        return 'two';
      }
      if(!this.isThirdSectionComplete){
        return 'three';
      }
      if(!this.isFourthSectionComplete){
        return 'four';
      }
      return 'loading';
    }
  },
  watch: {
    isOnBoardingComplete: {
      handler(isOnBoardingComplete){
        if(isOnBoardingComplete){
          if(this.$route.query.redirect){
            this.$router.push({name: this.$route.query.redirect});
          }else{
            console.log('onboarded user signed in', this.$store.getters['subscriptions/isSubscribed']);
            if(this.$store.getters['subscriptions/isSubscribed']){
              this.$router.push({name: 'AccountUserView'});
            }else{
              this.$router.push({name: 'download'});
            }
          }
        }
      },
      immediate: true
    },
    beforeMount(){
      this.customerTypes = this.shuffleArray(this.customerTypes);
      this.jobTitles = this.shuffleArray(this.jobTitles);
      this.useCases = this.shuffleArray(this.useCases);
    },
    isJobTitleSelected(){
      // Scroll the buttonFirstSectionSubmit into view
      this.$refs.buttonFirstSectionSubmit.$el.scrollIntoView({behavior: 'smooth'});
    }
  },
  methods: {
    shuffleArray(array) {
      const otherIndex = array.indexOf('Other');
      const lastIndex = array.length - 1;
      if (otherIndex !== -1) {
        [array[lastIndex], array[otherIndex]] = [array[otherIndex], array[lastIndex]];
      }
      for (let i = lastIndex - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  }
});
</script>
<style scoped lang="less">
@import '../../less/variables';
@import '../../less/mixins';

input{
  margin-bottom: 1em;
}
.options{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
  margin-bottom: 1em;
  &.wide{
    grid-template-columns: 1fr;
  }
  & > div{
    text-align: left;
    padding: 0.75em 1.25em;
    background: fade(@white, 5%);
    border-radius: 0.5em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.selected{
      background: @purpleVibrant
    }
  }
}

@media(min-width: @firstbreakpoint){
  .name-inputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
  }
}
</style>
